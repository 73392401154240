import { IEnvironment } from "./IEnvironment";

export const environment: IEnvironment = {
  SERVER_ORIGIN: "",
  production: false,
  useHash: true,
  hmr: false,

  apiUrl: "http://localhost:56668/api/",
  adminUrl: "http://localhost:56668/api/"
};
