import { Component, Input, NgZone, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import Quill from 'quill';
import { IModules } from './IModules';
import { v4 } from 'uuid';
import { UnsubscribeWrapperComponent } from '@shared/UnsubscribeWrapper';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'crm-custom-editor',
  templateUrl: './custom-editor.component.html',
  styleUrls: ['./custom-editor.component.scss'],
})
export class CustomEditorComponent extends UnsubscribeWrapperComponent implements OnInit {
  @Input() editorForm: FormControl;
  @Input() minHeight: string;
  @Input() body: string;

  // for default data
  emptyPlaceholderLabel: string;
  //
  editor: any;
  placeholders = [];
  modules: IModules;
  quill: Quill;
  dropDownUniqueName: string;
  constructor(private translate: TranslateService) {
    super();
  }

  ngOnInit(): void {
    // is needed if there are more CustomEditorComponent components on the same page
    this.dropDownUniqueName = v4();

    this.translate
      .stream('EDITOR.PLACEHOLDERS')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(placeholders => {
        this.modules = {
          toolbar: {
            container: [
              // [{ [this.dropDownUniqueName]: [...placeholders] }],
              [{ font: [] }, { size: [] }],
              ['bold', 'italic', 'underline', 'strike'],
              [{ color: [] }, { background: [] }],
              [{ script: 'super' }, { script: 'sub' }],
              [{ header: '1' }, { header: '2' }, 'blockquote', 'code-block'],
              [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
              [{ align: [] }],
              ['link', 'image', 'video', 'formula'],
              ['clean'],
            ],
            // handlers: {
            //   [this.dropDownUniqueName]: (value: string) => {
            //     if (value) {
            //       const cursorPosition = this.quill.getSelection().index;
            //       this.quill.insertText(cursorPosition, value);
            //       this.quill.setSelection(cursorPosition + value.length);
            //     }
            //   },
            // },
          },
        };
        // maybe use for placeholder transalation
        // if (this.quill) {
        //   this.quill.options.modules.toolbar.container[0] = [...placeholders]; // you need to update document element( not like this)
        //   this.changePlaceholdersValues();
        // }
      });
  }

  addBindingCreated(quill) {
    this.quill = quill;
    // this.changePlaceholdersValues();
    //// set variable to use on change
    // this.emptyPlaceholderLabel = document.querySelector(
    //   `.ql-${this.dropDownUniqueName} .ql-picker-label`
    // ).innerHTML;

    //// change placeholder container width
    // const placeholderContainer: any = document.querySelector(
    //   `.ql-snow .ql-picker.ql-${this.dropDownUniqueName}`
    // );
    // placeholderContainer.style.width = '108px';

    //// listen to translate changes
    // this.translate.stream('EDITOR.PLACEHOLDERS_TITLE').subscribe((value: string) => {
    //   document.querySelector(`.ql-${this.dropDownUniqueName} .ql-picker-label`).innerHTML =
    //     value + this.emptyPlaceholderLabel;
    // });
  }

  changePlaceholdersValues = () => {
    const placeholderPickerItems = Array.prototype.slice.call(
      document.querySelectorAll(`.ql-${this.dropDownUniqueName} .ql-picker-item`)
    );
    placeholderPickerItems.forEach(item => (item.textContent = item.dataset.value));
  };
}
