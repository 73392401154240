import { Component, Input, OnInit } from '@angular/core';
import { getColorByUserName } from '@shared/utilities/getColorByUserName';

@Component({
  selector: 'crm-avatar-photo',
  templateUrl: './avatar-photo.component.html',
  styleUrls: ['./avatar-photo.component.scss'],
})
export class AvatarPhotoComponent implements OnInit {
  @Input()
  public photoUrl: string;

  @Input()
  public name: string;
  @Input()
  public size: number;

  public showInitials = false;
  public initials: string;
  public circleColor: string;

  ngOnInit() {
    if (!this.photoUrl) {
      this.showInitials = true;
      this.createInititals();
      this.circleColor = getColorByUserName(this.name);
    }
  }

  private createInititals(): void {
    const name = this.name?.toUpperCase();
    const initialsRegex = name.match(/\b\w/g) || [];
    this.initials = ((initialsRegex.shift() || '') + (initialsRegex.pop() || '')).toUpperCase();
  }
}
