import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ClientDataService } from 'app/cityair/services/client-data.service';
import { take } from 'rxjs/operators';
import { AddClientComponent } from '../Dialogs/add-client/add-client.component';
import { DeleteClientComponent } from '../Dialogs/delete-client/delete-client.component';

@Component({
  selector: 'contract-client-info',
  templateUrl: './client-info.component.html',
  styleUrls: ['./client-info.component.scss']
})
export class ClientInfoComponent implements OnInit {

  columnsToDisplay:string[] = ['ClientId',"ExpirationDate","Actions"]
  clientDataList:any[];
  skip:number=0;
  take:number=5;
  length:number;
  constructor(
    private clientService:ClientDataService,
    private dialog:MatDialog
  ) { }


  ngOnInit(): void {
    this.refreshTable();
  }

  refreshTable(){
    this.clientDataList = [];
    this.clientService.getData(this.skip,this.take).pipe(take(1)).toPromise().then(result=>{  
      result.clientDataList.forEach(element => {
        this.clientDataList.push(element);
      });
      this.clientDataList = [...this.clientDataList];
      this.length = result.totalCount;
    });                        
  }

  addClient(){
    var adding = this.dialog.open(AddClientComponent,{
      minWidth:"10vw",
      minHeight:'20vh',
    });
    adding.afterClosed().subscribe(result=>{
      if(result != null){
        if(result.dataEvent == "added"){
          this.refreshTable();
        }
      }
    });
  }

  delete(id:string){
    var deleting = this.dialog.open(DeleteClientComponent,{
      minWidth:"10vw",
      minHeight:'20vh',
      data:{id:id}
    });
    deleting.afterClosed().subscribe(result =>{
      if(result!= null){
        if(result.dataEvent == "deleted"){
          this.refreshTable();
        }
      }
    });
  }

  onPageChange(e){
    this.skip = e.pageIndex * e.pageSize;
    this.take = e.pageSize;
    this.refreshTable();
  }

}


