import { NgModule } from "@angular/core";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { BrowserModule } from "@angular/platform-browser";

import { CoreModule } from "./core/core.module";
import { ThemeModule } from "./theme/theme.module";
import { RoutesModule } from "./routes/routes.module";
import { SharedModule } from "./shared/shared.module";
import { AppComponent } from "./app.component";

import { ToastrModule } from "ngx-toastr";
import { TranslateModule, TranslateLoader, TranslatePipe } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

// Required for AOT compilation
export const TranslateHttpLoaderFactory = (http: HttpClient): TranslateHttpLoader => {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
};

import { httpInterceptorProviders } from "@core/interceptors";
import { appInitializerProviders } from "@core/initializers";
import { FormlyConfigModule } from "./formly-config.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CityAirModule } from '../app/cityair/cityair.module'
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS
} from "@angular/material-moment-adapter";
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from "@angular/material/core";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { DatePipe } from "@angular/common";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    // check out
    CoreModule,
    ThemeModule,
    RoutesModule,
    SharedModule,
    CityAirModule,
    // not need to check out
    FormlyConfigModule.forRoot(),
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslateHttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BrowserAnimationsModule
  ],
  // check out
  providers: [
    httpInterceptorProviders,
    appInitializerProviders,
    DatePipe,
    // from mex
    TranslatePipe,
    // used for paginator translation
    {
      provide: MatPaginatorIntl
    },
    // used for date picker translation
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
