import { Injectable, Injector } from "@angular/core";
import { LOCATION_INITIALIZED } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { SettingsService } from "./settings.service";
import { takeUntil } from "rxjs/operators";
import { UnsubscribeWrapperComponent } from "@shared/UnsubscribeWrapper";
import { TranslateComponent } from "@theme/header/widgets/translate.component";
import { AuthenticationService } from "@core/authentication/authentication.service";

export const languageStorage: string = 'language';
@Injectable({
  providedIn: "root"
})
export class TranslateLangService extends UnsubscribeWrapperComponent {

  constructor(
    private injector: Injector,
    private translate: TranslateService,
    private settings: SettingsService,
    private authService: AuthenticationService
  ) {
    super();
  }

  get currentLanguage(): string {
    return (
      this.isLanguageValid(localStorage.getItem(languageStorage)) ??
      this.isLanguageValid(this.authService.languageCode) ??
      this.isLanguageValid(navigator.language) ??
      'en-US'
    );
  }

  load() {
    return new Promise<any>((resolve: any) => {
      const locationInitialized = this.injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
      locationInitialized.then(() => {
        const defaultLang = this.currentLanguage;
        this.settings.setLanguage(defaultLang);
        this.translate.setDefaultLang(defaultLang);
        this.translate.use(defaultLang).subscribe(
          () => {
            //console.log(`Successfully initialized '${defaultLang}' language.'`);
          },
          () => {
            //console.error(`Problem with '${defaultLang}' language initialization.'`);
          },
          () => {
            resolve(null);
          }
        );
      });
    });
  }

  isLanguageValid(languageCode: string): string {
    return languageCode && languageCode.match(/en-US|ro-RO/) ? languageCode : null;
  }
}
