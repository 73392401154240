import { Component } from "@angular/core";

@Component({
  selector: "app-branding",
  template: `
    <a class="matero-branding" href="#/">     
      <span class="matero-branding-name">ISDP - Monitorizare calitate aer</span>
    </a>
  `
})
export class BrandingComponent {}
