import { NgModule } from "@angular/core";
import { SharedModule } from "@shared";
import { HttpClientModule } from "@angular/common/http";

import { HelpComponent } from './components/help/help.component';
import { ClientInfoComponent } from './components/client-info/client-info.component';
import { AddClientComponent } from './components/Dialogs/add-client/add-client.component';
import { CredentialsMessageComponent } from './components/Dialogs/credentials-message/credentials-message.component';
import { DeleteClientComponent } from './components/Dialogs/delete-client/delete-client.component';
import { CategoryTypeComponent } from './components/category-type/category-type.component';
import { AddCategoryTypeComponent } from './components/Dialogs/add-category-type/add-category-type.component';
import { DeleteCategoryTypeComponent } from './components/Dialogs/delete-category-type/delete-category-type.component';
import { DeviceTypeComponent } from './components/device-type/device-type.component';
import { AddDeviceTypeComponent } from './components/Dialogs/add-device-type/add-device-type.component';
import { DeleteDeviceTypeComponent } from './components/Dialogs/delete-device-type/delete-device-type.component';
import { DeviceComponent } from './components/device/device.component';
import { AddDeviceComponent } from './components/Dialogs/add-device/add-device.component';
import { DeleteDeviceComponent } from './components/Dialogs/delete-device/delete-device.component';
import { EditDeviceComponent } from './components/Dialogs/edit-device/edit-device.component';

const COMPONENTS = [
  
];

@NgModule({
  declarations: [...COMPONENTS,HelpComponent, ClientInfoComponent, AddClientComponent, CredentialsMessageComponent, DeleteClientComponent, CategoryTypeComponent, AddCategoryTypeComponent, DeleteCategoryTypeComponent, DeviceTypeComponent, AddDeviceTypeComponent, DeleteDeviceTypeComponent, DeviceComponent, AddDeviceComponent, DeleteDeviceComponent, EditDeviceComponent],
  imports: [SharedModule, HttpClientModule]
})
export class CityAirModule {}
