<form class="add-item" #formLogin="ngForm" [formGroup]="addForm" style="text-align: center;">
    <h3>Introduceți datele senzorului</h3>
    <div class="form-group">
      <mat-form-field appearance="fill" class="matField">
        <input matInput type="text" placeholder="ID senzor" formControlName="deviceId">
      </mat-form-field>
    </div>
    <div class="form-group">
        <mat-form-field appearance="fill" class="matField">
          <input matInput type="number" placeholder="Latitudine" formControlName="latitude">
        </mat-form-field>
    </div>
    <div class="form-group">
        <mat-form-field appearance="fill" class="matField">
          <input matInput type="number" placeholder="Longitudine" formControlName="longitude">
        </mat-form-field>
    </div>
    <div class="form-group">
    <mat-form-field appearance="fill" class="matField">
        <input matInput type="text" placeholder="Nume" formControlName="name">
    </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field appearance="fill" class="matField">
          <mat-select formControlName="location" placeholder="Locație">
              <mat-option *ngFor="let item of locationList" [value]="item">{{item}}</mat-option>
            </mat-select>
      </mat-form-field>
      </div>
    <div class="form-group">
    <mat-form-field appearance="fill" class="matField">
        <mat-select formControlName="type" placeholder="Tip">
            <mat-option *ngFor="let item of typesList" [value]="item.type">{{item.type}}</mat-option>
          </mat-select>
    </mat-form-field>
    </div>
    <div class="form-group">
    <mat-form-field appearance="fill" class="matField">
        <mat-select formControlName="category" placeholder="Categorie">
            <mat-option *ngFor="let item of categoriesList" [value]="item.name">{{item.name}}</mat-option>
          </mat-select>
    </mat-form-field>
    </div>
    <div class="form-group">
      <button type="button" mat-raised-button (click)="fileInput.click()" style="margin:1rem">Choose File</button>
      {{getFileName(fileInput.value)}}
      <input hidden (change)="onFileSelected($event)" #fileInput multiple type="file">      
    </div>
    <button style="margin-left:1rem" mat-raised-button color="primary" [disabled]="addForm.invalid" class="signup-btn" (click)="register()">Adăugați</button>
    <button style="margin-left:1rem" mat-raised-button color="primary" class="signup-btn" (click)="closeDialog()">Renunțați</button>
</form> 