<form class="add-item" #formLogin="ngForm" [formGroup]="addForm" style="text-align: center;">
    <h1>Selectați durată (în ani)</h1>
    <div class="form-group">
      <mat-form-field appearance="fill" class="matField">
        <mat-select formControlName="duration">
            <mat-option *ngFor="let item of durations" [value]="item">{{ item }}</mat-option>
          </mat-select>
      </mat-form-field>
    </div>
    <button style="margin-left:1rem" mat-raised-button color="primary" [disabled]="addForm.invalid" class="signup-btn" (click)="register()">Adăugați</button>
    <button style="margin-left:1rem" mat-raised-button color="primary" class="signup-btn" (click)="closeDialog()">Închideți</button>
</form>      