<div class="w-full h-full" fxLayout="row wrap">
  <mat-card class="mat-elevation-z4 m-auto" style="max-width: 380px;">

    <img style="height: 150px;" class="logo" src="/assets/images/cityairlogo.png" />

    <mat-card-title class="text-center m-b-24">{{'login.title' | translate}}!</mat-card-title>

    <form class="form-field-full" [formGroup]="loginForm">
      <mat-form-field appearance="outline">
        <mat-label>{{'login.username' | translate}}:</mat-label>
        <input matInput formControlName="username" required>
        <mat-error *ngIf="username.invalid">
          {{'login.invalidEmail' | translate}}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{'login.password' | translate}}</mat-label>
        <input matInput type="password"
               formControlName="password" required>

      </mat-form-field>
      <button class="w-full" mat-raised-button color="primary"
              [disabled]="loginForm.invalid"
              (click)="login()">{{'login.login' | translate}}</button>
    </form>
  </mat-card>
</div>
