import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { IClientData } from '@shared/DTOs/IClientData';
import { IGeneralField } from '@shared/DTOs/IGeneralField';
import { GlobalHttpService } from './BaseService/global-http.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IClientsPaginationModel } from '@shared/DTOs/IClientsPaginationModel';

@Injectable({
  providedIn: 'root'
})
export class ClientDataService extends GlobalHttpService<IGeneralField> {

  constructor(private _httpClientInstance: HttpClient) {
    super(_httpClientInstance,environment.apiUrl,'Client');
  }
  headers = new HttpHeaders().set("Content-Type", "application/json");

  getData(skip:number,take:number){
    return this._httpClientInstance.get<IClientsPaginationModel>((`${this.url}${this.endpoint}`)+"/ClientData?skip="+skip+"&take="+take+"");
  }

  deleteClient(id:string){
    return this._httpClientInstance.post((`${this.url}${this.endpoint}`)+"/Delete",JSON.stringify(id),{ headers: this.headers })
  }
}
