import { NgModule } from "@angular/core";
import { SharedModule } from "@shared/shared.module";
import { AdminLayoutComponent } from "./admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./auth-layout/auth-layout.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { SidemenuComponent } from "./sidemenu/sidemenu.component";
import { AccordionDirective } from "./sidemenu/accordion.directive";
import { AccordionItemDirective } from "./sidemenu/accordionItem.directive";
import { AccordionAnchorDirective } from "./sidemenu/accordionanchor.directive";
import { HeaderComponent } from "./header/header.component";
import { BrandingComponent } from "./sidebar/branding.component";
import { TranslateComponent } from "./header/widgets/translate.component";
import { UserComponent } from "./header/widgets/user.component";
import { FiltersComponent } from "./filters/filters.component";
import { LoginComponent } from "./login/login.component";
import { PortalBrandingComponent } from "./sidebar/portal-branding.component";

@NgModule({
  declarations: [
    AdminLayoutComponent,
    AuthLayoutComponent,
    SidebarComponent,
    SidemenuComponent,
    AccordionDirective,
    AccordionItemDirective,
    AccordionAnchorDirective,
    HeaderComponent,
    BrandingComponent,
    TranslateComponent,
    UserComponent,
    FiltersComponent,
    LoginComponent,
    PortalBrandingComponent
  ],
  imports: [SharedModule]
})
export class ThemeModule {}
