import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeviceService } from 'app/cityair/services/device.service';

@Component({
  selector: 'cityair-delete-device',
  templateUrl: './delete-device.component.html',
  styleUrls: ['./delete-device.component.scss']
})
export class DeleteDeviceComponent implements OnInit {

  constructor(
    private deviceService:DeviceService,
    public dialogRef: MatDialogRef<DeleteDeviceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
  }

  deleteSensor(){
    this.deviceService.deleteDevice(this.data.id).subscribe(result =>{
    });
    setTimeout(()=>{
      this.dialogRef.close({dataEvent:"deleted"});
    },1000);
  }

  closeDialog(){
    this.dialogRef.close();
  }

}
