<div class="matero-sidebar-header" *ngIf="showHeader">
  <portal-branding></portal-branding>
  <span fxFlex></span>
  <mat-slide-toggle (change)="toggleCollapsed.emit()" [checked]="toggleChecked" *ngIf="showToggle">
  </mat-slide-toggle>
</div>

<div class="scrollbar-none">
  <app-sidemenu [ripple]="showToggle"></app-sidemenu>
</div>
<crm-filters></crm-filters>