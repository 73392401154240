import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'crm-display-error',
  templateUrl: './display-error.component.html',
  styleUrls: ['./display-error.component.scss'],
})
export class DisplayErrorComponent implements OnInit {
  message: string;
  isError: boolean;
  @Input() error: Error;
  constructor() {}

  ngOnInit(): void {
    this.isError = this.error instanceof Error;
    if (this.error && this.isError) {
      this.message = this.error?.message || 'Unknown error';
    }
  }
}
