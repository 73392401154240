<mat-card>
    <button mat-raised-button (click)="addCategory()" style="margin: 1rem 0 1rem 0;">Adăugați</button>
    <table mat-table [dataSource]="typesList" class="mat-elevation-z8">
    
      <ng-container matColumnDef="Type">
        <th mat-header-cell *matHeaderCellDef> Tip </th>
        <td mat-cell *matCellDef="let element"> {{element.type}} </td>
      </ng-container>
    
      <ng-container matColumnDef="Actions">
        <th mat-header-cell *matHeaderCellDef> </th>
        <td mat-cell *matCellDef="let element"><button mat-raised-button (click)="deleteType(element.id)"> Ștergere </button></td> 
      </ng-container>
    
      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
    
    </table>
  </mat-card>
  