import { Component, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DeleteConfirmationDialogComponent } from '@shared/dialogs/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { IObservableOptions } from '@shared/DTOs/ICardButton';
import { CardButtonStates } from '../../enums/CardButtonStates';

@Component({
  selector: 'crm-card-button',
  templateUrl: './card-button.component.html',
  styleUrls: ['./card-button.component.scss'],
})
export class CardButtonComponent implements OnInit, OnChanges {
  @Input() title: string;
  @Input() iconName: string;
  @Input() hoverColor: string;
  @Input() size: string;
  @Input() onClick: () => void;
  @Input() disabled: boolean;
  @Input() extraStyle: string;
  @Input() isLocked: CardButtonStates;
  @Input() observableOptions: IObservableOptions;
  @Input() item: any;

  // internals
  classNames: string;
  buttonColor: string;
  initialColor: string;
  states = CardButtonStates;
  currentState = CardButtonStates.INITIAL;
  selectedClickMethod: (item: any) => void;

  constructor(private dialog: MatDialog) {}

  ngOnChanges(): void {
    // disabled is not mandatory( default false)
    this.disabled = this.disabled === true ? true : false;
    this.initialColor = this.isLocked ? this.hoverColor : 'rgba(0, 0, 0, 0.2)';
    this.setClassNames();
  }

  ngOnInit(): void {
    // disabled is not mandatory( default false)
    this.disabled = this.disabled === true ? true : false;
    this.initialColor = this.isLocked ? this.hoverColor : 'rgba(0, 0, 0, 0.2)';
    this.buttonColor = this.initialColor;
    this.setClassNames();
    this.setSelectedClick();
  }

  setClassNames = () => {
    const enabledClassName = !this.disabled ? 'CardButtonEnabled' : '';
    this.classNames = `CardButton ${enabledClassName}`;
  };

  onMouseOver = () => {
    if (!this.disabled) {
      this.buttonColor = this.hoverColor;
    }
  };

  onMouseOut = () => {
    if (!this.disabled) {
      this.buttonColor = this.initialColor;
    }
  };

  setSelectedClick = () => {
    if (this.observableOptions && this.onClick) {
      throw new Error('Chose one : observable options or onclick');
    }
    if (this.observableOptions?.observable) {
      if (!this.observableOptions.observableCallback) {
        throw new Error('Observable need a callback');
      }
      this.selectedClickMethod = this.callObservable;
    } else {
      this.selectedClickMethod = this.onClick;
    }
  };

  callObservable = () => {
    if (this.observableOptions?.needConfirmation) {
      this.dialog.open(DeleteConfirmationDialogComponent, {
        disableClose: true,
        data: {
          message: this.observableOptions?.confirmationQuestion,
          onDelete: () => {
            this.currentState = CardButtonStates.PROGRESS;
            this.observableOptions?.observable.subscribe(
              response => {
                this.currentState = CardButtonStates.INITIAL;
                // only if we create a new client
                this.observableOptions?.observableCallback();
              },
              error => {
                this.currentState = CardButtonStates.ERROR;
                setTimeout(() => {
                  this.currentState = CardButtonStates.INITIAL;
                }, 3000);
              }
            );
          },
        },
      });
    } else {
      this.currentState = CardButtonStates.PROGRESS;
      this.observableOptions?.observable.subscribe(
        response => {
          this.currentState = CardButtonStates.INITIAL;
          this.observableOptions?.observableCallback();
        },
        error => {
          this.currentState = CardButtonStates.ERROR;
          setTimeout(() => {
            this.currentState = CardButtonStates.INITIAL;
          }, 3000);
        }
      );
    }
  };
}
