import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'contract-not-authorized',
  templateUrl: './not-authorized.component.html',
  styleUrls: ['./not-authorized.component.scss']
})
export class NotAuthorizedComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) data: any,  private _dialogRef: MatDialogRef<NotAuthorizedComponent>) { }

  ngOnInit(): void {
  }

}
