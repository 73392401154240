import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IDeviceType } from '@shared/DTOs/IDeviceType';
import { DeviceTypeService } from 'app/cityair/services/device-type.service';

@Component({
  selector: 'cityair-add-device-type',
  templateUrl: './add-device-type.component.html',
  styleUrls: ['./add-device-type.component.scss']
})
export class AddDeviceTypeComponent implements OnInit {

  addForm:FormGroup;

  constructor(
    private fb:FormBuilder,
    public dialogRef: MatDialogRef<AddDeviceTypeComponent>,
    private typeService:DeviceTypeService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { 
    this.addForm = this.fb.group({
      type:['',[Validators.required]]  
    });
  }

  ngOnInit(): void {
  }

  register(){
    let newType:IDeviceType={
      id:" ",
      type:this.addForm.get("type").value
    };
    this.typeService.addType(newType).subscribe(result =>{
    });
    setTimeout(()=>{
      this.dialogRef.close({dataEvent:"added"});
    },1000);
  }

  closeDialog(){
    this.dialogRef.close();
  }
}
