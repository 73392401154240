import { Component, OnInit, AfterViewInit } from "@angular/core";
import { PreloaderService } from "@core";

@Component({
  selector: "cityair-root",
  template: "<router-outlet></router-outlet>"
})
export class AppComponent implements OnInit, AfterViewInit {
  constructor(private preloader: PreloaderService) {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.preloader.hide();
  }
}
