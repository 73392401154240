<mat-card>
    <button mat-raised-button (click)="addDevice()" style="margin: 1rem 0 1rem 0;">Adăugați</button>
    <div class="form-group" style="float: right;">
      <mat-form-field appearance="standard" class="matField">
        <input matInput type="text" placeholder="Căutați după ID" id="search" [(ngModel)]="searchValue" (ngModelChange)="search()">
      </mat-form-field>
    </div>
    <table mat-table [dataSource]="devicesList" class="mat-elevation-z8">
    
      <ng-container matColumnDef="DeviceId" style="text-align: center;">
        <th mat-header-cell *matHeaderCellDef> Id </th>
        <td mat-cell *matCellDef="let element"> {{element.deviceId}} </td>
      </ng-container>
    
      <ng-container matColumnDef="Name">
        <th mat-header-cell *matHeaderCellDef> Nume </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>

      <ng-container matColumnDef="Location">
        <th mat-header-cell *matHeaderCellDef> Locație </th>
        <td mat-cell *matCellDef="let element"> {{element.location}} </td>
      </ng-container>

      <ng-container matColumnDef="Type">
        <th mat-header-cell *matHeaderCellDef> Tip </th>
        <td mat-cell *matCellDef="let element"> {{element.type}} </td>
      </ng-container>

      <ng-container matColumnDef="Category">
        <th mat-header-cell *matHeaderCellDef> Categorie </th>
        <td mat-cell *matCellDef="let element"> {{element.category}} </td>
      </ng-container>
    
      <ng-container matColumnDef="Actions">
        <th mat-header-cell *matHeaderCellDef> </th>
        <td mat-cell *matCellDef="let element"><button mat-raised-button (click)="delete(element._id)"> Ștergere </button> <button mat-raised-button (click)="update(element._id)" style="margin-left: 5px;"> Modificați </button></td>         
      </ng-container>
    
      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
    
    </table>
    <mat-paginator
    [length]="length"
    [pageSizeOptions]="[5,10,20]"
    showFirstLastButtons
    (page)="onPageChange($event)"
    >
  </mat-paginator>
  </mat-card>
  