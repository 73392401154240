import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ICategoryType } from '@shared/DTOs/ICategoryType';
import { CategoryTypeService } from 'app/cityair/services/category-type.service';
import { take } from 'rxjs/operators';
import { AddCategoryTypeComponent } from '../Dialogs/add-category-type/add-category-type.component';
import { DeleteCategoryTypeComponent } from '../Dialogs/delete-category-type/delete-category-type.component';


@Component({
  selector: 'cityair-category-type',
  templateUrl: './category-type.component.html',
  styleUrls: ['./category-type.component.scss']
})
export class CategoryTypeComponent implements OnInit {

  columnsToDisplay:string[] = ['Name',"Actions"]
  categoryList:ICategoryType[];

  constructor(
    private dialog:MatDialog,
    private categoryService:CategoryTypeService
  ) { }

  ngOnInit(): void {
    this.refreshTable();
  }

  refreshTable(){
    this.categoryList = [];
    this.categoryService.getCategories().pipe(take(1)).toPromise().then(result=>{
      this.categoryList = result;
    })                  
  }

  addCategory(){
    var adding = this.dialog.open(AddCategoryTypeComponent,{
      minWidth:"10vw",
      minHeight:'20vh',
    });
    adding.afterClosed().subscribe(result=>{
      if(result != null){
        if(result.dataEvent == "added"){
          this.refreshTable();
        }
      }
    });
  }

  deleteCategory(id:string){
    var deleting = this.dialog.open(DeleteCategoryTypeComponent,{
      minWidth:"10vw",
      minHeight:'20vh',
      data:{id:id}
    });
    deleting.afterClosed().subscribe(result =>{
      if(result!= null){
        if(result.dataEvent == "deleted"){
          this.refreshTable();
        }
      }
    });
  }

}
