import { Pipe, PipeTransform } from '@angular/core';
import { IDevice } from '@shared/DTOs/IDevice';

@Pipe({
  name: 'searchFilter'
})
export class SearchFilterPipe implements PipeTransform {

  transform(devices:IDevice[],searchValue:string): IDevice[] {
    if(!devices || !searchValue){
      return devices;
    }
    return devices.filter(dev =>
      dev.deviceId.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()));
  }

}
