import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { languageStorage, SettingsService, TranslateLangService } from '@core';

@Component({
  selector: 'app-translate',
  template: `
    <button mat-icon-button class="matero-toolbar-button" [matMenuTriggerFor]="menu">
      <mat-icon>g_translate</mat-icon>
    </button>

    <mat-menu #menu="matMenu">
      <button mat-menu-item *ngFor="let lang of langs | keyvalue" (click)="useLanguage(lang.key)">
        <span>{{ lang.value }}</span>
      </button>
    </mat-menu>
  `,
  styles: [],
})
export class TranslateComponent {
  public static languageStorage: string = "language";
  langs = {
    'en-US': 'English',
    'ro-RO': 'Română',
  };

  constructor(
    private translate: TranslateService,
    translateLangService: TranslateLangService,
    private settings: SettingsService,
    ) {

    translate.addLangs(['en-US', 'ro-RO']);
    translate.setDefaultLang('en-US');

    translate.use(translateLangService.currentLanguage);
  }

  useLanguage(language: string) {
    localStorage.setItem(languageStorage, language);
    this.translate.use(language);
    this.settings.setLanguage(language);
  }
}
