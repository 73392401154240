import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { AuthenticationService } from "@core/authentication/authentication.service";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  //
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        switch (err.status) {
          case 401:
          case 403: {
            // TODO:Discuss auto logout if 401 Unauthorized or 403 Forbidden response returned from api
            this.authenticationService.logout();
            break;
          }
          case 404:
          case 500:
            this.goto(`sessions/${err.status}`);
            break;
          default:
            if (err instanceof HttpErrorResponse) {
              //console.error("ERROR", err);
              //this.toastr.error(err.error.msg || `${err.status} ${err.statusText}`);
            }
            break;
        }
        const error = err.error.message || err.statusText;
        return throwError(error);
      })
    );
  }

  private goto(url: string) {
    setTimeout(() => this.router.navigateByUrl(url));
  }
}
