import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IDevice } from '@shared/DTOs/IDevice';
import { DeviceService } from 'app/cityair/services/device.service';
import { take } from 'rxjs/operators';
import { AddDeviceComponent } from '../Dialogs/add-device/add-device.component';
import { DeleteDeviceComponent } from '../Dialogs/delete-device/delete-device.component';
import { EditDeviceComponent } from '../Dialogs/edit-device/edit-device.component';

@Component({
  selector: 'cityair-device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.scss']
})
export class DeviceComponent implements OnInit {

  columnsToDisplay:string[] = ['DeviceId',"Name",'Location',"Type","Category","Actions"];
  devicesList:IDevice[];
  skip:number=0;
  take:number=5;
  length:number;
  searchValue:string;

  constructor(
    private dialog:MatDialog,
    private deviceService:DeviceService
  ) { }

  ngOnInit(): void {
    this.refreshTable();
  }

  refreshTable(){
    this.devicesList = [];
    this.deviceService.getPaginatedDevices(this.skip,this.take).pipe(take(1)).toPromise().then(result=>{  
      result.devicesList.forEach(element => {
        this.devicesList.push(element);
      });
      this.devicesList=[...this.devicesList];
      this.length = result.totalCount;
    });                   
  }

  search(){
    if(this.searchValue != ""){
      this.deviceService.searchDevice(this.searchValue).pipe(take(1)).toPromise().then(result=>{  
        this.devicesList = result;
      });                   
    }else{
      this.refreshTable();
    }     
  }

  addDevice(){
    var adding = this.dialog.open(AddDeviceComponent,{
      minWidth:"10vw",
      minHeight:'20vh',
    });
    adding.afterClosed().subscribe(result=>{
      if(result != null){
        if(result.dataEvent == "added"){
          this.refreshTable();
        }
      }
    });
  }

  delete(id:string){
    var deleting = this.dialog.open(DeleteDeviceComponent,{
      minWidth:"10vw",
      minHeight:'20vh',
      data:{id:id}
    });
    deleting.afterClosed().subscribe(result =>{
      if(result!= null){
        if(result.dataEvent == "deleted"){
          this.refreshTable();
        }
      }
    });
  }

  update(id:string){
    var updating = this.dialog.open(EditDeviceComponent,{
      minWidth:"10vw",
      minHeight:'20vh',
      data:{id:id}
    });
    updating.afterClosed().subscribe(result =>{
      if(result!= null){
        if(result.dataEvent == "updated"){
          this.refreshTable();
        }
      }
    });
  }

  onPageChange(e){
    this.skip = e.pageIndex * e.pageSize;
    this.take = e.pageSize;
    this.refreshTable();
  }

}
