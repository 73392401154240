import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClientDataService } from 'app/cityair/services/client-data.service';

@Component({
  selector: 'cityair-delete-client',
  templateUrl: './delete-client.component.html',
  styleUrls: ['./delete-client.component.scss']
})
export class DeleteClientComponent implements OnInit {

  constructor(
    private clientDataService:ClientDataService,
    public dialogRef: MatDialogRef<DeleteClientComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
  }

  deleteClient(){
    this,this.clientDataService.deleteClient(this.data.id).subscribe(result =>{

    });
    setTimeout(()=>{
      this.dialogRef.close({dataEvent:"deleted"});
    },1000);
  }

  closeDialog(){
    this.dialogRef.close();
  }

}
