import { HttpClient } from '@angular/common/http';
import { BaseDTO } from '@shared/DTOs/BaseDTO';
import { IGeneralField } from '@shared/DTOs/IGeneralField';
import { Observable } from 'rxjs';

export class GlobalHttpService<T extends BaseDTO> {
  //
  constructor(private httpClient: HttpClient, protected url: string, protected endpoint: string) {}

  get = (id: number): Observable<T> => {
    return this.httpClient.get<T>(`${this.url}/${this.endpoint}/${id}`);
  };

  add = (item: T | FormData): Observable<T> => {
    return this.httpClient.post<T>(`${this.url}/${this.endpoint}`, item);
  };

  update = (item: T | FormData|any): Observable<T> => {
    return this.httpClient.put<T>(`${this.url}/${this.endpoint}`, item);
  };

  delete(id: number): Observable<any> {
    return this.httpClient.delete(`${this.url}/${this.endpoint}/${id}`);
  }

  simpleSearch(value: string) {
    return this.httpClient.get<IGeneralField[]>(`${this.url}/${this.endpoint}/list/${value}`);
  }

  listAll = (): Observable<T[]> => {
    return this.httpClient.get<T[]>(`${this.url}/${this.endpoint}/list`);
  };

}
