import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ICategoryType } from '@shared/DTOs/ICategoryType';
import { CategoryTypeService } from 'app/cityair/services/category-type.service';

@Component({
  selector: 'cityair-add-category-type',
  templateUrl: './add-category-type.component.html',
  styleUrls: ['./add-category-type.component.scss']
})
export class AddCategoryTypeComponent implements OnInit {

  addForm:FormGroup;

  constructor(
    private fb:FormBuilder,
    public dialogRef: MatDialogRef<AddCategoryTypeComponent>,
    private categoryService:CategoryTypeService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { 
    this.addForm = this.fb.group({
      name:['',[Validators.required]]  
    });
  }

  ngOnInit(): void {
  }

  register(){
    let newCategory:ICategoryType={
      id:" ",
      name:this.addForm.get("name").value
    };
    this.categoryService.addCategory(newCategory).subscribe(result =>{
    });
    setTimeout(()=>{
      this.dialogRef.close({dataEvent:"added"});
    },1000);
  }

  closeDialog(){
    this.dialogRef.close();
  }

}
