import { COMMA, ENTER } from "@angular/cdk/keycodes";
import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { UnsubscribeWrapperComponent } from "@shared/UnsubscribeWrapper";
import { isObservable, Observable } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "crm-user-autocomplete",
  templateUrl: "./user-autocomplete.component.html",
  styleUrls: ["./user-autocomplete.component.scss"]
})
export class UserAutocompleteComponent
  extends UnsubscribeWrapperComponent
  implements OnInit, OnChanges {
  //
  @Input() label: string;
  @Input() selectable = true;
  @Input() removable = true;
  @Input() itemsReceived: IUserAutocomplete[] | Observable<IUserAutocomplete>;
  @Input() value: IUserAutocomplete[];
  @Input() chipFontSize: number;
  @Input() acceptMultiple = true;
  @Input() showUserBadge = true;
  @Input() isRequired = true;
  @Input() inputChangedCallback: (value: string) => void;
  @Input() emitSelectedItems: (items: IUserAutocomplete[]) => void;

  @ViewChild("itemsInput") itemsInput: ElementRef<HTMLInputElement>;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  // internals
  isObservable = false;
  itemsCopy: IUserAutocomplete[];
  itemsUsed: IUserAutocomplete[];
  selectedItems: IUserAutocomplete[] = [];
  itemsCtrl = new FormControl();

  hasValue = false;
  constructor() {
    super();
    this.itemsCtrl.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        if (typeof value === "string") {
          this.hasValue = true;
          if (!isObservable(this.itemsReceived)) {
            this.inputChanged(value);
          }
          if (this.inputChangedCallback) {
            this.inputChangedCallback(value);
          } else {
            if (!isObservable(this.itemsReceived)) {
              this.inputChanged(value);
            }
          }
        }
      });
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.isObservable = isObservable(this.itemsReceived);

    if (!isObservable(this.itemsReceived)) {
      this.itemsCopy = this.itemsReceived;
      this.itemsUsed = this.itemsReceived;
    }
  }

  ngOnInit(): void {
    this.isObservable = isObservable(this.itemsReceived);
    this.selectedItems = this.value;

    if (!isObservable(this.itemsReceived)) {
      this.itemsCopy = this.itemsReceived;
      this.itemsUsed = this.itemsReceived;
    }
  }

  remove(contactPerson: IUserAutocomplete): void {
    const index = this.selectedItems.indexOf(contactPerson);

    if (index >= 0) {
      this.selectedItems.splice(index, 1);
    }
    this.emitSelectedItems(this.selectedItems);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const value: IUserAutocomplete = event.option.value;
    const isInArray = this.selectedItems.find((i) => i.id === value.id);
    if (!isInArray) {
      if (this.acceptMultiple) {
        this.selectedItems = [...this.selectedItems, value];
        this.itemsInput.nativeElement.value = "";
        this.itemsCtrl.setValue(null);
      } else {
        this.selectedItems = [value];
        this.itemsInput.nativeElement.value = "";
        this.itemsCtrl.setValue(null);
      }
    }
    if (this.emitSelectedItems) {
      this.emitSelectedItems(this.selectedItems);
    }
  }

  inputChanged = (value: string) => {
    this.itemsUsed = value
      ? this.itemsCopy.filter((contact) =>
          contact.name?.toLowerCase().includes(value?.toLowerCase())
        )
      : this.itemsCopy;
  };
}

export interface IUserAutocomplete {
  id: number;
  name: string;
  email?: string;
  phone?: string;
}
