import { Component } from '@angular/core';
import { AuthenticationService } from '@core';
import { IUser } from '@shared/DTOs/Auth/IUser';
import { UnsubscribeWrapperComponent } from '@shared/UnsubscribeWrapper';
import { getFullName } from '@shared/utilities/getFullName';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-user',
  template: `
    <button
      class="matero-toolbar-button matero-avatar-button"
      mat-button
      [matMenuTriggerFor]="menu"
    >
      <div style="display: inline-block;">
        <crm-avatar-photo [photoUrl]="null" [name]="userFullName" size="24"></crm-avatar-photo>
      </div>

      <span class="matero-username" fxHide.lt-sm>{{
        user?.tokenInfo?.email || 'User'
      }}</span>
    </button>

    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="logout()">
        <mat-icon>exit_to_app</mat-icon>
        <span>{{ 'SHARED.LOGOUT' | translate }}</span>
      </button>
    </mat-menu>
  `,
})
export class UserComponent extends UnsubscribeWrapperComponent {
  user: IUser;
  userFullName: string;
  constructor(private authenticationService: AuthenticationService) {
    super();
    authenticationService.user$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(user => {
      this.user = user;
      this.userFullName = this.user?.tokenInfo?.email;
    });
  }

  logout() {
    this.authenticationService.logout();
  }
}
