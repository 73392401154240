import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "@env/environment";
import { AuthenticationService } from "@core/authentication/authentication.service";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  //
  constructor(private authenticationService: AuthenticationService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add auth header with jwt if user is logged in and request is to api url
    const user = this.authenticationService.user;
    const isLoggedIn = user && user.token;
    const isApiUrl = request.url.startsWith(environment.apiUrl);

    if (isLoggedIn && isApiUrl) {
      if (user.expireDate < new Date()) {
        // if token expired logout
        this.authenticationService.logout();
      }
      const verifiedToken = user.token.startsWith("Bearer ") ? user.token : `Bearer ${user.token}`;

      // TODO: add roles
      request = request.clone({
        setHeaders: {
          Authorization: verifiedToken
        }
        // withCredentials: true, // TODO: check this
      });
    }

    return next.handle(request);
  }
}
