import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CategoryTypeService } from 'app/cityair/services/category-type.service';

@Component({
  selector: 'cityair-delete-category-type',
  templateUrl: './delete-category-type.component.html',
  styleUrls: ['./delete-category-type.component.scss']
})
export class DeleteCategoryTypeComponent implements OnInit {

  constructor(
    private categoryService:CategoryTypeService,
    public dialogRef: MatDialogRef<DeleteCategoryTypeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
  }

  deleteCategory(){
    this,this.categoryService.deleteCategory(this.data.id).subscribe(result =>{
    });
    setTimeout(()=>{
      this.dialogRef.close({dataEvent:"deleted"});
    },1000);
  }

  closeDialog(){
    this.dialogRef.close();
  }

}
