import { Component, Input, OnInit } from "@angular/core";
import { ICardButton } from "@shared/DTOs/ICardButton";

@Component({
  selector: "crm-card",
  templateUrl: "./card.component.html",
  styleUrls: ["./card.component.scss"]
})
export class CardComponent implements OnInit {
  @Input() title: string;
  @Input() onTitleClick: () => void;
  @Input() buttons: ICardButton[];
  @Input() displayArrow: boolean;

  titleClass: string;
  ngOnInit(): void {
    if (this.displayArrow === undefined) {
      this.displayArrow = true;
    }
    const isTitleClickable =
      this.onTitleClick !== null && this.onTitleClick !== undefined;
    if (isTitleClickable) {
      this.titleClass = "ClickableTitle";
      this.displayArrow = this.displayArrow && true;
    } else {
      this.titleClass = "";
      this.displayArrow = this.displayArrow && false;
    }
  }
}
