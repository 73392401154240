import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ICategoryType } from '@shared/DTOs/ICategoryType';
import { IDevice } from '@shared/DTOs/IDevice';
import { IDeviceType } from '@shared/DTOs/IDeviceType';
import { CategoryTypeService } from 'app/cityair/services/category-type.service';
import { DeviceTypeService } from 'app/cityair/services/device-type.service';
import { DeviceService } from 'app/cityair/services/device.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'cityair-add-device',
  templateUrl: './add-device.component.html',
  styleUrls: ['./add-device.component.scss']
})
export class AddDeviceComponent implements OnInit {

  addForm:FormGroup;
  typesList:IDeviceType[];
  categoriesList:ICategoryType[];
  files:File[]=[];

  locationList=["Interior","Exterior"]

  constructor(
    private fb:FormBuilder,
    public dialogRef: MatDialogRef<AddDeviceComponent>,
    private typeService:DeviceTypeService,
    private categoryService:CategoryTypeService,
    private deviceService:DeviceService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.addForm = this.fb.group({
      deviceId:['',[Validators.required]],
      latitude:['',[Validators.required]],
      longitude:['',[Validators.required]],
      name:['',[Validators.required]],
      location:['',[Validators.required]],
      type:['',[Validators.required]],
      category:['',[Validators.required]],
    });
   }

  ngOnInit(): void {
    this.getData();
  }

  getFileName(value:any){
    let name = value.replace(/.*[\/\\]/, '');
    return name;
  }

  register(){
    let device:IDevice={
      _id:"",
      deviceId:this.addForm.get("deviceId").value,
      longitude:this.addForm.get("longitude").value,
      latitude:this.addForm.get("latitude").value,
      name:this.addForm.get("name").value,
      location:this.addForm.get("location").value,
      softwareVersion:"",
      created:new Date(),
      type:this.addForm.get("type").value,
      category:this.addForm.get("category").value,
      file:this.files
    }
    this.deviceService.addDevice(device).subscribe(result =>{
    });
    setTimeout(()=>{
      this.dialogRef.close({dataEvent:"added"});
    },1000);
  }

  onFileSelected(event: any){
    let temp = [].slice.call(event.target.files);
    temp.forEach(element => {
      this.files.push(element);
    });
  }

  getData(){
    this.typeService.getTypes().pipe(take(1)).toPromise().then(result=>{  
      this.typesList = result;
    });   
    this.categoryService.getCategories().pipe(take(1)).toPromise().then(result=>{  
      this.categoriesList = result;
    });                        
  }

  closeDialog(){
    this.dialogRef.close();
  }

}
