import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ICategoryType } from '@shared/DTOs/ICategoryType';
import { IDevice } from '@shared/DTOs/IDevice';
import { IDeviceType } from '@shared/DTOs/IDeviceType';
import { CategoryTypeService } from 'app/cityair/services/category-type.service';
import { DeviceTypeService } from 'app/cityair/services/device-type.service';
import { DeviceService } from 'app/cityair/services/device.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'cityair-edit-device',
  templateUrl: './edit-device.component.html',
  styleUrls: ['./edit-device.component.scss']
})
export class EditDeviceComponent implements OnInit {

  addForm:FormGroup;
  typesList:IDeviceType[];
  categoriesList:ICategoryType[];
  files:File[]=[];

  locationList=["Interior","Exterior"]

  device:IDevice;

  constructor(
    private fb:FormBuilder,
    public dialogRef: MatDialogRef<EditDeviceComponent>,
    private typeService:DeviceTypeService,
    private categoryService:CategoryTypeService,
    private deviceService:DeviceService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.addForm = this.fb.group({
      deviceId:['',[Validators.required]],
      latitude:['',[Validators.required]],
      longitude:['',[Validators.required]],
      name:['',[Validators.required]],
      location:['',[Validators.required]],
      type:['',[Validators.required]],
      category:['',[Validators.required]],
    });
   }

  ngOnInit(): void {
    this.getData();
    this.getDeviceById();
  }

  getFileName(value:any){
    let name = value.replace(/.*[\/\\]/, '');
    return name;
  }

  update(){
    let device:IDevice={
      _id:this.device._id,
      deviceId:this.addForm.get("deviceId").value,
      longitude:this.addForm.get("longitude").value,
      latitude:this.addForm.get("latitude").value,
      name:this.addForm.get("name").value,
      location:this.addForm.get("location").value,
      softwareVersion:this.device.softwareVersion,
      created:this.device.created,
      type:this.addForm.get("type").value,
      category:this.addForm.get("category").value,
      file:this.files
    }
    this.deviceService.updateDevice(device).subscribe(result =>{
    });
    setTimeout(()=>{
      this.dialogRef.close({dataEvent:"updated"});
    },1000);
  }

  onFileSelected(event: any){
    let temp = [].slice.call(event.target.files);
    temp.forEach(element => {
      this.files.push(element);
    });
  }

  getData(){
    this.typeService.getTypes().pipe(take(1)).toPromise().then(result=>{  
      this.typesList = result;
    });   
    this.categoryService.getCategories().pipe(take(1)).toPromise().then(result=>{  
      this.categoriesList = result;
    });                        
  }

  closeDialog(){
    this.dialogRef.close();
  }

  getDeviceById(){
    this.deviceService.getDeviceById(this.data.id).pipe(take(1)).toPromise().then(result =>{
      this.addForm.get("location").setValue(result.location),
      this.addForm.get("deviceId").setValue(result.deviceId),
      this.addForm.get("longitude").setValue(result.longitude),
      this.addForm.get("latitude").setValue(result.latitude),
      this.addForm.get("name").setValue(result.name),
      this.addForm.get("type").setValue(result.type),
      this.addForm.get("category").setValue(result.category)
      this.device = result;
    });
  }

}
