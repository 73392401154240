import { Component, OnInit } from "@angular/core";


@Component({
  selector: "crm-filters",
  templateUrl: "./filters.component.html",
  styleUrls: ["./filters.component.scss"]
})
export class FiltersComponent implements OnInit {

  constructor(
  ) {
  }
  ngOnInit(): void {
  }

  // done
 
}
