import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { IDeviceType } from '@shared/DTOs/IDeviceType';
import { IGeneralField } from '@shared/DTOs/IGeneralField';
import { GlobalHttpService } from './BaseService/global-http.service';

@Injectable({
  providedIn: 'root'
})
export class DeviceTypeService extends GlobalHttpService<IGeneralField> {

  constructor(private _httpClientInstance: HttpClient) {
    super(_httpClientInstance,environment.apiUrl,'DeviceType');
   }
 
  headers = new HttpHeaders().set("Content-Type", "application/json");

   public addType(type:IDeviceType){
     return this._httpClientInstance.post<IDeviceType>((`${this.url}${this.endpoint}`), JSON.stringify(type),{ headers: this.headers });
   }

   public deleteType(id:string){
     return this._httpClientInstance.post(`${this.url}${this.endpoint}/Delete`,JSON.stringify(id),{ headers: this.headers });
   }

   public getTypes(){
     return this._httpClientInstance.get<IDeviceType[]>(`${this.url}${this.endpoint}`);
   }
}
