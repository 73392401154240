import { Component, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";

// only use this class for BehaviorSubject or forms(is not needed for for http and routing)
// use  .pipe(takeUntil(this.ngUnsubscribe)) before .subscribe
@Component({
  template: "<div></div>"
})
export class UnsubscribeWrapperComponent implements OnDestroy {
  protected ngUnsubscribe = new Subject();

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
