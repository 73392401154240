import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IDeviceType } from '@shared/DTOs/IDeviceType';
import { DeviceTypeService } from 'app/cityair/services/device-type.service';
import { take } from 'rxjs/operators';
import { AddDeviceTypeComponent } from '../Dialogs/add-device-type/add-device-type.component';
import { DeleteDeviceTypeComponent } from '../Dialogs/delete-device-type/delete-device-type.component';

@Component({
  selector: 'cityair-device-type',
  templateUrl: './device-type.component.html',
  styleUrls: ['./device-type.component.scss']
})
export class DeviceTypeComponent implements OnInit {

  columnsToDisplay:string[] = ['Type',"Actions"]
  typesList:IDeviceType[];

  constructor(
    private dialog:MatDialog,
    private typeService:DeviceTypeService
  ) { }

  ngOnInit(): void {
    this.refreshTable();
  }

  refreshTable(){
    this.typesList = [];
    this.typeService.getTypes().pipe(take(1)).toPromise().then(result=>{
      this.typesList = result;
    })                  
  }

  addCategory(){
    var adding = this.dialog.open(AddDeviceTypeComponent,{
      minWidth:"10vw",
      minHeight:'20vh',
    });
    adding.afterClosed().subscribe(result=>{
      if(result != null){
        if(result.dataEvent == "added"){
          this.refreshTable();
        }
      }
    });
  }

  deleteType(id:string){
    var deleting = this.dialog.open(DeleteDeviceTypeComponent,{
      minWidth:"10vw",
      minHeight:'20vh',
      data:{id:id}
    });
    deleting.afterClosed().subscribe(result =>{
      if(result!= null){
        if(result.dataEvent == "deleted"){
          this.refreshTable();
        }
      }
    });
  }

}
