import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IClientCredentials } from '@shared/DTOs/IClientCredentials';
import { ClientCredentialsService } from 'app/cityair/services/client-credentials.service';
import { CredentialsMessageComponent } from '../credentials-message/credentials-message.component';


@Component({
  selector: 'contract-add-client',
  templateUrl: './add-client.component.html',
  styleUrls: ['./add-client.component.scss']
})
export class AddClientComponent implements OnInit {

  addForm:FormGroup;
  durations:number[]=[1,2];
  clientData:IClientCredentials = {
    clientId:"",
    clientSecret:"",
    duration:0
  };
  
  constructor(
    private dialog:MatDialog,
    private fb:FormBuilder,
    private clietCredentialsService:ClientCredentialsService,
    public dialogRef: MatDialogRef<AddClientComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.addForm = this.fb.group({
      duration:['',[Validators.required]]  
    });
   }

  ngOnInit(): void {
    this.clietCredentialsService.getPartialCredentials().subscribe(result =>{
      this.clientData.clientId = result.clientId;
      this.clientData.clientSecret = result.clientSecret;
    })
  }

  closeDialog(){
    this.dialogRef.close();
  }

  register(){
    this.clientData.duration = this.addForm.get("duration").value;
    this.clietCredentialsService.generateCredentials(this.clientData).subscribe(result =>{
      
    });   
    this.dialog.open(CredentialsMessageComponent,{
      disableClose: true,
      minWidth:"10vw",
      minHeight:'20vh',
      data:{secret:this.clientData.clientSecret}
    });    
    setTimeout(()=>{
      this.dialogRef.close({dataEvent:"added"});
    },1000);
  }
}
