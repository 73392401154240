<div [class]="classNames"
     [title]="title | translate"
     (click)="currentState===states.INITIAL && !disabled && selectedClickMethod(item)"
     [style.width]="size"
     [style.height]="size"
     [style.color]="buttonColor"
     [style]="extraStyle ||''"
     (mouseover)="onMouseOver()" (mouseout)="onMouseOut()">
  <mat-icon *ngIf="currentState===states.INITIAL">{{iconName}}
  </mat-icon>
  <mat-icon *ngIf="currentState===states.ERROR" style="color: red;">error_outline
  </mat-icon>
  <mat-spinner *ngIf="currentState===states.PROGRESS" diameter="20"></mat-spinner>
</div>
