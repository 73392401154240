import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

import { MaterialModule } from "../material.module";
import { MaterialExtensionsModule } from "@ng-matero/extensions";
import { FlexLayoutModule } from "@angular/flex-layout";
import { NgProgressModule } from "ngx-progressbar";
import { NgProgressHttpModule } from "ngx-progressbar/http";
import { NgProgressRouterModule } from "ngx-progressbar/router";
import { NgSelectModule } from "@ng-select/ng-select";
import { FormlyModule } from "@ngx-formly/core";
import { FormlyMaterialModule } from "@ngx-formly/material";
import { ToastrModule } from "ngx-toastr";
import { TranslateModule } from "@ngx-translate/core";

import { ErrorCodeComponent } from "./components/error-code/error-code.component";
import { AvatarPhotoComponent } from "./components/avatar-photo/avatar-photo.component";
import { QuillModule } from "ngx-quill";
import { CustomEditorComponent } from "./components/custom-editor/custom-editor.component";
import {
  DxButtonModule,
  DxChartModule,
  DxDataGridModule,
  DxDateBoxModule,
  DxNumberBoxModule,
  DxPieChartModule,
  DxSchedulerModule,
  DxSpeedDialActionModule
} from "devextreme-angular";
import { CardButtonComponent } from "./components/card-button/card-button.component";
import { CardComponent } from "./components/card/card.component";
import { OptionsComponent } from "./components/options/options.component";
import { EnumToArrayPipe } from "./pipes/enum-to-array.pipe";
import { DeleteConfirmationDialogComponent } from "./dialogs/delete-confirmation-dialog/delete-confirmation-dialog.component";
import { FormDialogComponent } from "./dialogs/form-dialog/form-dialog.component";
import { DisplayErrorComponent } from "./components/display-error/display-error.component";
import { UserAutocompleteComponent } from "./components/user-autocomplete/user-autocomplete.component";
import { UnsubscribeWrapperComponent } from "./UnsubscribeWrapper";
import { AttachmentsDialogComponent } from "./dialogs/attachments-dialog/attachments-dialog.component";
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { NotAuthorizedComponent } from './components/not-authorized/not-authorized.component';
import { SearchFilterPipe } from './pipes/search-filter.pipe';

const THIRD_MODULES = [
  MaterialModule,
  MaterialExtensionsModule,
  FlexLayoutModule,
  NgProgressModule,
  NgProgressRouterModule,
  NgProgressHttpModule,
  NgSelectModule,
  FormlyModule,
  FormlyMaterialModule,
  ToastrModule,
  TranslateModule,
  QuillModule.forRoot()
];

const DEVEXPRESS_MODULES = [
  DxDataGridModule,
  DxSchedulerModule,
  DxSpeedDialActionModule,
  DxDateBoxModule,
  DxChartModule,
  DxPieChartModule,
  DxNumberBoxModule,
  DxButtonModule
];

const COMPONENTS = [
  CardComponent,
  CardButtonComponent,
  OptionsComponent,
  ErrorCodeComponent,
  AvatarPhotoComponent,
  CustomEditorComponent,
  DisplayErrorComponent,
  UserAutocompleteComponent,
  UnsubscribeWrapperComponent
];

const DIALOGS = [
  FormDialogComponent,
  DeleteConfirmationDialogComponent,
  AttachmentsDialogComponent
];

const COMPONENTS_DYNAMIC = [];
const DIRECTIVES = [];
const PIPES = [EnumToArrayPipe];

@NgModule({
  declarations: [...COMPONENTS, ...DIALOGS, ...COMPONENTS_DYNAMIC, ...DIRECTIVES, ...PIPES, BreadcrumbComponent, NotAuthorizedComponent, SearchFilterPipe],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    ...THIRD_MODULES,
    ...DEVEXPRESS_MODULES
  ],
  exports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    ...THIRD_MODULES,
    ...DEVEXPRESS_MODULES,
    ...COMPONENTS,
    ...DIALOGS,
    ...DIRECTIVES,
    ...PIPES
  ],
  entryComponents: COMPONENTS_DYNAMIC
})
export class SharedModule {}
