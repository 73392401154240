<mat-toolbar class="matero-toolbar">
  <button mat-icon-button class="matero-toolbar-button" *ngIf="showToggle"
          (click)="toggleSidenav.emit()">
    <mat-icon>menu</mat-icon>
  </button>
  <app-branding></app-branding>
  <span fxFlex></span>

  <button mat-icon-button class=" matero-toolbar-button" fxHide.lt-sm
          (click)="toggleFullscreen()">
    <mat-icon>fullscreen</mat-icon>
  </button>

  <app-translate></app-translate>

  <app-user></app-user>
</mat-toolbar>