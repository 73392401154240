import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { IGeneralField } from '@shared/DTOs/IGeneralField';
import { IClientCredentials } from '../../shared/DTOs/IClientCredentials';
import { IClientData } from '../../shared/DTOs/IClientData';
import { GlobalHttpService } from './BaseService/global-http.service';

@Injectable({
  providedIn: 'root'
})
export class ClientCredentialsService extends GlobalHttpService<IGeneralField> {

  constructor(private _httpClientInstance: HttpClient) {
    super(_httpClientInstance,environment.apiUrl,'ClientCredentials');
  }
  headers = new HttpHeaders().set("Content-Type", "application/json");

  generateCredentials(cred:IClientCredentials){
    return this._httpClientInstance.post<IClientCredentials>((`${this.url}${this.endpoint}`)+"/GenerateCredentials", JSON.stringify(cred),{ headers: this.headers });
  }

  getPartialCredentials(){
    return this._httpClientInstance.get<IClientData>((`${this.url}${this.endpoint}`)+"/PartialCredentials");
  }
}


