<div fxLayout="row"
     class="OptionsContainer"
     [style.top]="mainHeaderSizeScroll">
  <header
          fxFlex="100"
          [style.top]="mainHeaderSizeScroll"
          fxLayoutAlign="center center"
          fxLayout="column"
          *ngIf='isCollapsed'>
    <mat-card
              [style.top]="mainHeaderSizeScroll"
              class="OptionsCard"
              style="width:250px;"
              fxLayout="row"
              fxLayoutAlign="space-between">
      <mat-icon (click)="onClickNew()" matTooltip="{{ 'SHARED.ADD' | translate }}">add</mat-icon>
      <mat-icon matTooltip="{{ 'SHARED.SORT' | translate }}"
                (click)="onClickSort()"
                [class]="isSortAsc?'SortReversed':''">sort</mat-icon>
      <mat-icon matTooltip="{{ 'SHARED.EXPORT' | translate }}">import_export</mat-icon>
      <mat-icon (click)="toogleActionBar()">select_all</mat-icon>
    </mat-card>
  </header>

  <header fxFlex="100"
          fxLayout="column"
          dir="ltr"
          *ngIf="!isCollapsed">
    <mat-card class="OptionsCard"
              fxLayout="row wrap"
              fxLayoutAlign="space-evenly"
              [style.top]="mainHeaderSizeScroll">
      <button mat-button (click)="onClickNew()">
        <mat-icon>add</mat-icon>
        {{ 'SHARED.ADD' | translate }}
      </button>
      <button mat-button (click)="onClickSort()">
        <mat-icon [class]="isSortAsc?'SortReversed':''">sort</mat-icon>
        {{ 'SHARED.SORT' | translate }}
      </button>
      <button mat-button>
        <mat-icon>import_export</mat-icon>
        {{ 'SHARED.EXPORT' | translate }}
      </button>
      <button mat-button (click)="toogleActionBar()">
        <mat-icon>select_all</mat-icon>
      </button>
    </mat-card>
  </header>
</div>
