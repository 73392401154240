import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService, StartupService } from "@core";
import { TranslateService } from "@ngx-translate/core";
import { DeleteConfirmationDialogComponent } from "@shared/dialogs/delete-confirmation-dialog/delete-confirmation-dialog.component";
import { IUser } from "@shared/DTOs/Auth/IUser";
import { UnsubscribeWrapperComponent } from "@shared/UnsubscribeWrapper";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent extends UnsubscribeWrapperComponent implements OnInit {
  loginForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private startup: StartupService,
    private authenticationService: AuthenticationService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private route: ActivatedRoute
  ) {
    super();
    this.loginForm = this.fb.group({
      username: ["", [Validators.required, Validators.email]],
      password: ["", [Validators.required]]
    });
  }

  ngOnInit() {
    let token = this.route.snapshot.queryParamMap.get('token');
    if (token) {
      this.authenticationService.loginWithToken(token);
      this.startup.load().then(() => {
        this.router.navigateByUrl("/");
      });
    }
  }

  get username() {
    return this.loginForm.get("username");
  }

  get password() {
    return this.loginForm.get("password");
  }

  login() {
    let account: IUser = null;
    this.authenticationService.login(this.username.value, this.password.value).subscribe({
      next: acc => {
        account = acc;
        this.startup.load().then(() => {
          this.router.navigateByUrl("/");
        });
      },
      error: err => {
        this.translate
          .get("login.invalidCredentials")
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(message => {
            this.dialog.open(DeleteConfirmationDialogComponent, {
              data: {
                message,
                showCancel: false
              },
              autoFocus: false,
              disableClose: true
            });
          });
      }
    });
  }
}
