import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { ICategoryType } from '@shared/DTOs/ICategoryType';
import { IGeneralField } from '@shared/DTOs/IGeneralField';
import { GlobalHttpService } from './BaseService/global-http.service';

@Injectable({
  providedIn: 'root'
})
export class CategoryTypeService extends GlobalHttpService<IGeneralField> {

  constructor(private _httpClientInstance: HttpClient) {
    super(_httpClientInstance,environment.apiUrl,'CategoryType');
   }
 
  headers = new HttpHeaders().set("Content-Type", "application/json");

   public addCategory(category:ICategoryType){
     return this._httpClientInstance.post<ICategoryType>((`${this.url}${this.endpoint}`), JSON.stringify(category),{ headers: this.headers });
   }

   public deleteCategory(id:string){
     return this._httpClientInstance.post(`${this.url}${this.endpoint}/Delete`,JSON.stringify(id),{ headers: this.headers });
   }

   public getCategories(){
     return this._httpClientInstance.get<ICategoryType[]>(`${this.url}${this.endpoint}`);
   }

}
