const hashCode = (str: string) => {
  // java String#hashCode
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    // tslint:disable-next-line: no-bitwise
    hash = str.charCodeAt(i) + ((hash << 4) - hash);
  }
  return hash;
};

const intToRGB = (i: number) => {
  // tslint:disable-next-line: no-bitwise
  const c = (i & 0x00ffffff).toString(16).toUpperCase();
  return `${"00000".substring(0, 6 - c.length)}${c}`;
};

export const getColorByUserName = (userName: string): string => {
  return `#${intToRGB(hashCode(userName))}`;
};
