import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { IDevice } from '@shared/DTOs/IDevice';
import { IGeneralField } from '@shared/DTOs/IGeneralField';
import { IPaginatedDevice } from '@shared/DTOs/IPagintedDevice';
import { GlobalHttpService } from './BaseService/global-http.service';

@Injectable({
  providedIn: 'root'
})
export class DeviceService extends GlobalHttpService<IGeneralField> {

  constructor(private _httpClientInstance: HttpClient) {
    super(_httpClientInstance,environment.apiUrl,'');
   }
 
  headers = new HttpHeaders().set("Content-Type", "application/json");

  public getDevices(){
    return this._httpClientInstance.get<IDevice[]>(`${this.url}${this.endpoint}devices`);
  }

  public addDevice(device:IDevice){
    const formData = new FormData();
    formData.append("deviceId",device.deviceId);
    formData.append("latitude",String(device.latitude));
    formData.append("longitude",String(device.longitude));
    formData.append("name",device.name);
    formData.append("location",device.location);
    formData.append("type",device.type);
    formData.append("softwareVersion",device.softwareVersion);
    formData.append("created",String(device.created));
    formData.append("category",device.category);
    device.file.forEach(element => {
      formData.append("files",element);
    }); 
    return this._httpClientInstance.post<any>(`${this.url}${this.endpoint}Register/Device`,formData);
  }

  public deleteDevice(id:string){
    return this._httpClientInstance.post(`${this.url}${this.endpoint}`+"Delete",JSON.stringify(id),{ headers: this.headers });
  }

  public updateDevice(device:IDevice){
    const formData = new FormData();
    formData.append("_id",device._id);
    formData.append("deviceId",device.deviceId);
    formData.append("latitude",String(device.latitude));
    formData.append("longitude",String(device.longitude));
    formData.append("name",device.name);
    formData.append("location",device.location);
    formData.append("type",device.type);
    formData.append("softwareVersion",device.softwareVersion);
    formData.append("created",String(device.created));
    formData.append("category",device.category);
    device.file.forEach(element => {
      formData.append("files",element);
    }); 
    return this._httpClientInstance.put<any>(`${this.url}${this.endpoint}Device`,formData);
  }

  public getDeviceById(id:string){
    return this._httpClientInstance.get<IDevice>(`${this.url}${this.endpoint}devices/${id}`);
  }

  public getPaginatedDevices(skip:number,take:number){
    return this._httpClientInstance.get<IPaginatedDevice>(`${this.url}${this.endpoint}`+"DevicesPaged?skip="+skip+"&take="+take);
  }

  public searchDevice(searchValue:string){
    return this._httpClientInstance.get<IDevice[]>(`${this.url}${this.endpoint}SearchValue?searchValue=`+searchValue);
  }
  
}
