import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  ChangeDetectorRef
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { UnsubscribeWrapperComponent } from "@shared/UnsubscribeWrapper";
import * as screenfull from "screenfull";

@Component({
  selector: "app-header",
  host: {
    class: "matero-header"
  },
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent extends UnsubscribeWrapperComponent implements OnInit {
  @Input() showToggle = true;
  @Output() toggleSidenav = new EventEmitter<void>();

  _query = "";
  searchCtrl = new FormControl();

  private get screenfull(): screenfull.Screenfull {
    return screenfull as screenfull.Screenfull;
  }

  constructor(
  ) {
    super();
  }

  ngOnInit() {
  }

  toggleFullscreen() {
    if (this.screenfull.isEnabled) {
      this.screenfull.toggle();
    }
  }

}
