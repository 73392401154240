import { Component } from "@angular/core";
import { environment } from "@env/environment";

@Component({
  selector: "portal-branding",
  template: `
    <div class="matero-branding" style="cursor:pointer;user-select:none" (click)="onClick()">
      <img style="margin-left:2px;" src="./assets/images/cityairlogo.png" class="matero-branding-logo-expanded" alt="logo" />
    </div>
  `
})
export class PortalBrandingComponent {
  onClick = (): void => {
    if (environment.production) {
      window.location.href = "/#/dashboard";
    }
  };

  constructor(){}
}
