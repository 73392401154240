import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { IUser } from "@shared/DTOs/Auth/IUser";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import jwt_decode from "jwt-decode";
import { ITokenPayload } from "@shared/DTOs/Auth/ITokenPayload";
import { environment } from "@env/environment";
import { SHA3 } from "crypto-js";
@Injectable({
  providedIn: "root"
})
export class AuthenticationService {
  //
  public static AUTH_TOKEN_NAME = "token";

  //
  private userSubject: BehaviorSubject<IUser>;
  public user$: Observable<IUser>;

  constructor(private router: Router, private http: HttpClient) {
    const token = localStorage.getItem(AuthenticationService.AUTH_TOKEN_NAME);

    this.userSubject = new BehaviorSubject<IUser>(this.getDecodedUser(token));
    this.user$ = this.userSubject.asObservable();
  }

  get languageCode(): string {
    return this.user?.languageCode;
  }

  public get user(): IUser {
    return this.userSubject.value;
  }

  get isAuthenticated() {
    const user = this.user;
    return user && user.token && user.expireDate > new Date();
  }

  login(email: string, password: string) {
    //
      return this.http
      .post<string>(`${environment.apiUrl}Login/HelpLogin`, {
        email,
        password
      })
      .pipe(
        map(token => {
          const account: IUser = this.getDecodedUser(token);
          // store jwt token in local storage
          localStorage.setItem(AuthenticationService.AUTH_TOKEN_NAME, token);
          this.userSubject.next(this.getDecodedUser(token));
          return account;
        })
      );    
      
    
  }
  loginWithToken(token: string){
    localStorage.setItem(AuthenticationService.AUTH_TOKEN_NAME, token);
    this.userSubject.next(this.getDecodedUser(token));
  }

  logout = async (): Promise<void> => {
    // remove user from local storage to log user out
    localStorage.removeItem(AuthenticationService.AUTH_TOKEN_NAME);
    this.userSubject.next(null);
    if (environment.production) {
      window.location.href = "/#/auth/login";
    } else {
      await this.router.navigate(["/auth"]);
    }
  };

  private getDecodedUser(token: string): IUser {
    try {
      const tokenInfo: ITokenPayload = jwt_decode(token);
      const user: IUser = {
        id: +tokenInfo?.id,
        firstName: tokenInfo?.firstName,
        lastName: tokenInfo?.lastName,
        email: tokenInfo?.email,
        unitId: tokenInfo?.unitate,
        languageCode: tokenInfo?.languageCode,
        expireDate: new Date(tokenInfo?.exp * 1000),
        token,
        tokenInfo
      };
      return user;
    } catch (error) {
      return null;
    }
  }
}
