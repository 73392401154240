import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  templateUrl: "./delete-confirmation-dialog.component.html",
  styleUrls: ["./delete-confirmation-dialog.component.scss"]
})
export class DeleteConfirmationDialogComponent {
  message: string;
  onDelete: () => void;
  constructor(
    public dialogRef: MatDialogRef<DeleteConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { message: string; onDelete: () => void; showCancel: boolean }
  ) {
    if (data.showCancel === undefined || data.showCancel === null) {
      data.showCancel = true;
    }
    this.message = data.message;
    this.onDelete = data.onDelete;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  Save() {
    if (this.onDelete) {
      this.onDelete();
    }
    this.dialogRef.close();
  }
}
