import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'crm-options',
  templateUrl: './options.component.html',
  styleUrls: ['./options.component.scss'],
})
export class OptionsComponent implements OnInit {
  @Input() onClickNew: () => void;
  @Input() onClickSort: () => void;
  @Input() isSortAsc: boolean;
  mainHeaderSize: string;
  mainHeaderSizeScroll: string;
  topOffset: string;
  isCollapsed = true;
  constructor() {}
  ngOnInit(): void {
    this.mainHeaderSize =
      document.getElementsByClassName('matero-toolbar')[0].clientHeight.toString() + 'px';
    this.mainHeaderSizeScroll =
      (document.getElementsByClassName('matero-toolbar')[0].clientHeight + 15).toString() + 'px';
  }

  toogleActionBar() {
    this.isCollapsed = !this.isCollapsed;
  }
}
