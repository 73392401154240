<!-- Used for simple array -->
<mat-form-field style="width: 100%;" *ngIf="!isObservable">
  <mat-label>{{label}}</mat-label>
  <mat-chip-list #ChipList>
    <mat-chip
              [style.font-size]="chipFontSize+'px'"
              *ngFor="let item of selectedItems"
              [selectable]="selectable"
              [removable]="removable"
              (removed)="remove(item)">
      {{item.name || item.email}}
      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
    </mat-chip>
    <input
           [required]="isRequired"
           [placeholder]="'SHARED.SEARCH'|translate"
           #itemsInput
           [matAutocomplete]="auto"
           [formControl]="itemsCtrl"
           [matChipInputFor]="ChipList"
           [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let itemReceived of itemsUsed" [value]="itemReceived"
                style="min-height: 42px; line-height: 1.15; height: auto; padding: 8px 16px; white-space: normal;">
      <div fxLayout="row"
           fxLayoutAlign="start center">
        <crm-avatar-photo *ngIf="showUserBadge"
                          [photoUrl]="null"
                          [name]="itemReceived.email" size=28
                          style="margin-right: 10px;"></crm-avatar-photo>
        <div style="width: 100%"
             fxLayout="column"
             fxLayoutAlign="center start">
          <div style="margin-top: 5px;"
               [style.font-size]="chipFontSize+'px'"> {{itemReceived.name }} </div>
          <div *ngIf="itemReceived.email"
               style=" margin-top: 5px;"
               fxLayout="row"
               fxLayoutAlign="start center"
               [style.font-size]="(chipFontSize)+'px'">
            <div style="font-weight: 600;margin-right: 5px;">Email:</div>
            <div>{{itemReceived.email}}</div>
          </div>
        </div>
      </div>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
<!-- End of used for simple array -->

<!-- Used for observables -->
<mat-form-field style="width: 100%;" *ngIf="isObservable">
  <mat-label>{{label}}</mat-label>
  <mat-chip-list #ChipList>
    <mat-chip
              [style.font-size]="chipFontSize+'px'"
              *ngFor="let item of selectedItems"
              [selectable]="selectable"
              [removable]="removable"
              (removed)="remove(item)">
      {{item.name || item.email}}
      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
    </mat-chip>
    <input
           [required]="isRequired"
           [placeholder]="'SHARED.SEARCH'|translate"
           #itemsInput
           [matAutocomplete]="auto"
           [formControl]="itemsCtrl"
           [matChipInputFor]="ChipList"
           [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">

    <mat-option *ngFor="let itemReceived of itemsReceived |async" [value]="itemReceived"
                style="min-height: 42px; line-height: 1.15; height: auto; padding: 8px 16px; white-space: normal;">
      <div fxLayout="row"
           fxLayoutAlign="start center">
        <crm-avatar-photo *ngIf="showUserBadge"
                          [photoUrl]="null"
                          [name]="itemReceived.name" size=28
                          style="margin-right: 10px;"></crm-avatar-photo>
        <div style="width: 100%"
             fxLayout="column"
             fxLayoutAlign="center start">
          <div style="margin-top: 5px;"
               [style.font-size]="chipFontSize+'px'"> {{itemReceived.name }} </div>
          <div *ngIf="itemReceived.email"
               style=" margin-top: 5px;"
               fxLayout="row"
               fxLayoutAlign="start center"
               [style.font-size]="(chipFontSize)+'px'">
            <div style="font-weight: 600;margin-right: 5px;">Email:</div>
            <div>{{itemReceived.email}}</div>
          </div>
        </div>
      </div>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
<!-- End of used for observables -->
