<h1 mat-dialog-title [translate]="title" class="DialogTitle"></h1>
<div mat-dialog-content>
  <div class="DialogContainer" *ngIf="errorsArray && errorsArray.length > 0">
    <div class="CardContainer">
      <crm-display-error *ngFor="let error of errorsArray" [error]="error"></crm-display-error>
    </div>
  </div>
  <ng-content select="[form-data]"></ng-content>
</div>
<div mat-dialog-actions>
  <button mat-button
          (click)="onCancel()">{{ 'SHARED.CANCEL' | translate }}</button>
  <div fxLayout="row nowrap"
       fxLayoutAlign="center center">
    <mat-spinner diameter="20" *ngIf="confirmState==confirmButtonStates.PROGRESS"></mat-spinner>
    <mat-icon style="color: red;" *ngIf="confirmState==confirmButtonStates.ERROR">error_outline
    </mat-icon>
    <mat-icon style="color: green;" *ngIf="confirmState==confirmButtonStates.SUCCEEDED">
      check_circle_outline</mat-icon>
    <button mat-button
            style="padding-left: 5px;"
            [disabled]="isDisabled"
            (click)="onSave()">
      {{ 'SHARED.CONFIRM' | translate }}
    </button>
    <div *ngIf="confirmTooltip && confirmTooltip!==''"
         style="font-size: 12px;"
         fxLayout="row nowrap"
         fxLayoutAlign="center center">
      <mat-icon
                style="font-size: 20px;height: auto!important;width: auto!important;">
        info</mat-icon>
      <div style="font-style: italic;">{{confirmTooltip}}</div>
    </div>
  </div>
</div>
<div style="width: 100vh;" class="DO_NOT_DELETE"></div>
