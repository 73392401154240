import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'contract-credentials-message',
  templateUrl: './credentials-message.component.html',
  styleUrls: ['./credentials-message.component.scss']
})
export class CredentialsMessageComponent implements OnInit {

  clientSecret:string;
  constructor(
    public dialogRef: MatDialogRef<CredentialsMessageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { 
    this.clientSecret = this.data.secret;
  }

  ngOnInit(): void {
  }

  closeDialog(){
    this.dialogRef.close();
  }

}
