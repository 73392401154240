import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';

export interface BreadCrumb {
  label: string;
  url: string;
}

@Component({
  selector: 'contract-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  breadcrumbs: BreadCrumb[] = [];

  isDisplayed: boolean = false;

  startBreadCrumbsLabels: BreadCrumb[] = [
    {
      label: "Contract",
      url: "contract"
    },
    {
      label: "Configuration",
      url: "config"
    },
  ];

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd || event instanceof NavigationStart),
        distinctUntilChanged(),
        map(() => this.activatedRoute),
        map(route => route.firstChild),
        switchMap(route => route.data)
      )
      .subscribe(data => {
        if (this.startBreadCrumbsLabels.find(l => l.label === data.breadcrumb)) {
          this.breadcrumbs = [{ label: data.breadcrumb, url: this.router.url }];
        } else {
          const foundIndex = this.breadcrumbs.findIndex(b => b.label === data.breadcrumb);
          if (foundIndex === -1) {
            this.breadcrumbs = [
              ...this.breadcrumbs,
              { label: data.breadcrumb, url: this.router.url }
            ];
          } else {
            this.breadcrumbs = this.breadcrumbs.slice(0, foundIndex + 1);
          }

          const firstBreadCrumbLabel = this.breadcrumbs[0].label;
          if (!this.startBreadCrumbsLabels.find(l => l.label === firstBreadCrumbLabel)) {
            const found = this.startBreadCrumbsLabels.find(b =>
              b.label.includes(firstBreadCrumbLabel?.substring(0, firstBreadCrumbLabel.length / 2))
            );
            if (found) {
              this.breadcrumbs = [found, ...this.breadcrumbs];
            }
          }
        }
      });
  }

  ngOnInit(): void {
    this.router.events.subscribe((val: NavigationStart) => {
      this.isDisplayed = this.checkIfIsDisplayed(val.url);
    });
    this.isDisplayed = this.checkIfIsDisplayed(this.router.url);
  }

  checkIfIsDisplayed = (url: string): boolean => {
    switch (url) {
      case "/contract":
      case "/config":
      default: {
        return true;
      }
    }
  };

}
