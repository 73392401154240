import { leadingComment } from "@angular/compiler";
import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { AuthenticationService, MenuService } from "@core";
import { IUser } from "@shared/DTOs/Auth/IUser";


@Component({
  selector: "app-sidemenu",
  templateUrl: "./sidemenu.component.html",
  styleUrls: ["./sidemenu.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class SidemenuComponent{
  // NOTE: Ripple effect make page flashing on mobile
  @Input() ripple = false;

  menus = this.menu.getAll();

  constructor(private menu: MenuService,
    private authService:AuthenticationService) {
     
    }

  user = this.authService.user;
  // Delete empty values and rebuild route
  buildRoute(routes: string[]) {
    let route = "";
    routes.forEach(item => {
      if (item && item.trim()) {
        route += "/" + item.replace(/^\/+|\/+$/g, "");
      }
    });
    return route;
  }
}
