import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { CardButtonStates } from "@shared/enums/CardButtonStates";

@Component({
  selector: "crm-form-dialog",
  templateUrl: "./form-dialog.component.html",
  styleUrls: ["./form-dialog.component.scss"]
})
export class FormDialogComponent implements OnInit, OnChanges {
  @Input() title: string;
  @Input() confirmState: CardButtonStates;
  @Input() formIsValid: boolean;
  @Input() confirmTooltip: string = null;
  @Input() onCancel: () => void;
  @Input() onSave: () => void;
  @Input() errorsArray: Error[];

  confirmButtonStates = CardButtonStates;
  isDisabled = false;
  ngOnChanges(changes: SimpleChanges): void {
    this.confirmState = this.confirmState || CardButtonStates.INITIAL;
    switch (this.confirmState) {
      case CardButtonStates.INITIAL: {
        this.isDisabled = !this.formIsValid;

        break;
      }
      case CardButtonStates.ERROR:
      case CardButtonStates.PROGRESS:
      case CardButtonStates.SUCCEEDED: {
        this.isDisabled = true;
        break;
      }
    }
  }
  ngOnInit(): void {
    this.confirmState = this.confirmState || CardButtonStates.INITIAL;
    switch (this.confirmState) {
      case CardButtonStates.INITIAL: {
        this.isDisabled = !this.formIsValid;

        break;
      }
      case CardButtonStates.ERROR:
      case CardButtonStates.PROGRESS:
      case CardButtonStates.SUCCEEDED: {
        this.isDisabled = true;
        break;
      }
    }
  }
}
