<mat-card class="CrmCard" fxLayout="column">

  <div fxLayout="row" fxFlex="100" [class]="titleClass" (click)="onTitleClick && onTitleClick()">

    <div class="CardTitle" fxLayoutAlign="flex-start center"
         fxLayoutAlign.xs="center center"
         fxLayoutAlign.sm="center center">
      {{title}}
    </div>
    <mat-icon *ngIf="displayArrow" mat-card-avatar
              class="TitleIcon"
              fxLayoutAlign="end start"
              alt="Enter"
              fxFlex>last_page
    </mat-icon>
  </div>
  <div fxLayout="row"
       fxFlex="100"
       fxLayout.xs="column"
       fxLayout.sm="column"
       class="RowContainer">
    <div fxLayout="column"
         fxFlex="10em"
         fxFlex.xs="6em"
         fxFlex.sm="6em"
         fxLayoutAlign="space-evenly"
         style="margin: 0 20px 0 20px;">
      <ng-content select="[dropdown]"></ng-content>
      <div fxLayout="row"
           fxLayoutAlign="space-around center">
        <crm-card-button *ngFor="let button of buttons"
                         [title]="button.title"
                         [iconName]="button.iconName"
                         [hoverColor]="button.hoverColor"
                         [size]="button.size"
                         [onClick]="button.click"
                         [observableOptions]="button.observableOptions"
                         [disabled]="button.disabled"
                         [extraStyle]="button.extraStyle"></crm-card-button>
      </div>
    </div>
    <div fxLayout="column" style="width: 100%;">
      <ng-content select="[metadata]"></ng-content>
      <ng-content select="[details]"></ng-content>
    </div>
  </div>
</mat-card>