<div
     *ngIf="isDisplayed && breadcrumbs && breadcrumbs.length>0"
     fxLayout="row"
     fxLayoutAlign="start center"
     style="background-color: white; padding: 10px;overflow-x: hidden;">
    <div *ngFor="let breadcrumb of breadcrumbs; last as isLast">
        <div fxLayout="row"
             fxLayoutAlign="start center"
             *ngIf="!isLast">
            <a
               [routerLink]="breadcrumb.url"> {{ breadcrumb.label }}</a>
            <div style="margin: 0 5px 0 5px;user-select: none;font-size: 22px;">></div>
        </div>
        <div *ngIf="isLast" style="font-weight: 700;user-select: none;">
            {{ breadcrumb.label }}
        </div>

    </div>
</div>