import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";

import { Menu, MenuService } from "./menu.service";
import { SettingsService } from "./settings.service";
import { AuthenticationService } from "@core";
import { IUser } from "@shared/DTOs/Auth/IUser";

@Injectable({
  providedIn: "root"
})
export class StartupService {

  constructor(
    private menu: MenuService,
    private http: HttpClient,
    private settings: SettingsService,
    private authService:AuthenticationService
  ) {}

   load(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(`assets/data/menu.json?_t=${Date.now()}`)
        .pipe(
          catchError(res => {
            resolve();
            return throwError(res);
          })
        )
        .subscribe(
          (res: any) => {
            let totalMenu = this.removeMenuItem(res);
            this.menu.recursMenuForTranslation(totalMenu, "menu");
            this.menu.set(totalMenu);
          },
          () => {
            reject();
          },
          () => {
            resolve();
          }
        );
    });
  }

  removeMenuItem(abc:any){
    let realMenu:Menu[]=[];
    let check = this.checkUser(this.authService.user);
    abc.menu.forEach(element => {
      if(element.route === "type" && check == false){
      }else if(element.route === "category" && check == false){
      }
      else if(element.route === "device" && check == false){        
      }
      else{
        realMenu.push(element);
      }
    });
    return realMenu;
  }

  checkUser(user:IUser){
    if(user?.email === "admin@cityair.com"){
      return true;
    }
    return false;
  }
}
