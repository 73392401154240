import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { environment } from "@env/environment";
import { AuthGuard } from "@core";
import { AdminLayoutComponent } from "../theme/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "../theme/auth-layout/auth-layout.component";
import { LoginComponent } from "@theme/login/login.component";
import { NotAuthGuard } from "@core/authentication/not-auth.guard";
import { HelpComponent } from "app/cityair/components/help/help.component";
import { ClientInfoComponent } from "app/cityair/components/client-info/client-info.component";
import { CategoryTypeComponent } from "app/cityair/components/category-type/category-type.component";
import { DeviceTypeComponent } from "app/cityair/components/device-type/device-type.component";
import { AdminGuard } from "@core/authentication/admin.guard";
import { DeviceComponent } from "app/cityair/components/device/device.component";
const routes: Routes = [
  {
    path: "",
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      { path: "", redirectTo: "help", pathMatch: "full" },  
      {
        path: "help",
        component: HelpComponent,
      },
      {
        path: "client-info",
        component: ClientInfoComponent,
      },
      {
        path: "category",
        component: CategoryTypeComponent,
        canActivate:[AdminGuard]
      },
      {
        path: "type",
        component: DeviceTypeComponent,
        canActivate:[AdminGuard]
      },
      {
        path: "device",
        component: DeviceComponent,
        canActivate:[AdminGuard]
      },
      {
        path: "sessions",
        loadChildren: () => import("./errorPages/errorPages.module").then(m => m.SessionsModule),
        data: { title: "Sessions", titleI18n: "Sessions" }
      }
    ]
  },

  {
    path: "auth",
    canActivate: [NotAuthGuard],
    canActivateChild: [NotAuthGuard],
    component: AuthLayoutComponent,
    children: [
      { path: "", redirectTo: "login", pathMatch: "full" },
      {
        path: "login",
        component: LoginComponent,
        data: { title: "Login", titleI18n: "Login" }
      }
    ]
  },
  { path: '**', redirectTo: 'help' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: environment.useHash
    })
  ],
  exports: [RouterModule]
})
export class RoutesRoutingModule {}
