import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  templateUrl: "./attachments-dialog.component.html",
  styleUrls: ["./attachments-dialog.component.scss"]
})
export class AttachmentsDialogComponent {
  
}
