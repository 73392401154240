import { Injectable } from "@angular/core";
import {
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  UrlSegment,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
} from "@angular/router";
import { AuthenticationService } from "@core";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class NotAuthGuard implements CanActivate, CanActivateChild, CanLoad {
  //
  constructor(private authenticationService: AuthenticationService,private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.process();
  }
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.process();
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.process();
  }

  private process(): boolean {
    if (this.authenticationService.isAuthenticated) {
      this.router.navigateByUrl("/");
    }
    return !this.authenticationService.isAuthenticated;
  }
}
