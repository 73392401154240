<mat-card>
  <button mat-raised-button (click)="addClient()" style="margin: 1rem 0 1rem 0;">Adăugați</button>
  <table mat-table [dataSource]="clientDataList" class="mat-elevation-z8">
  
    <ng-container matColumnDef="ClientId" style="text-align: center;">
      <th mat-header-cell *matHeaderCellDef> Id </th>
      <td mat-cell *matCellDef="let element"> {{element.clientId}} </td>
    </ng-container>
  
    <ng-container matColumnDef="ExpirationDate">
      <th mat-header-cell *matHeaderCellDef> Dată de expirare </th>
      <td mat-cell *matCellDef="let element"> {{element.expirationDate | date :'medium'}} </td>
    </ng-container>
  
    <ng-container matColumnDef="Actions">
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let element"><button mat-raised-button (click)="delete(element.clientId)"> Ștergere </button></td> 
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
  
  </table>
  <mat-paginator
  [length]="length"
  [pageSizeOptions]="[5,10,20]"
  showFirstLastButtons
  (page)="onPageChange($event)"
  >

  </mat-paginator>
</mat-card>
